window.addEventListener("turbolinks:load", () => {
  setup_nsfw_flags();
});

function setup_nsfw_flags (e) {
  let nsfw_flag_input = document.querySelector("#user_nsfw_show");
  let nsfw_everyone_flag_input = document.querySelector("#user_nsfw_show_everyone");

  nsfw_flag_input && nsfw_flag_input.addEventListener('change', function (e) {
    nsfw_everyone_flag_input.disabled = !this.checked;
  });
}

window.addEventListener("turbolinks:load", () => {
  const form = document.querySelector(".content form.generic");
  if(!form) { return; }

  let perrors = form.querySelector("#errors");
  let inputs = form.querySelectorAll("input,textarea");

  inputs.forEach(element => {
    element.addEventListener('keydown', function (e) {
      perrors.textContent = "";
    });
  });

  form.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);

    if(data.error) {
      perrors.textContent = data.error;
    } else if(data.redirect) {
      Turbolinks.visit(data.redirect);
    }
  });

  form.addEventListener("ajax:error", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);

    if(data.error) {
      errors.textContent = data.error;
    } else {
      errors.textContent = "Server Error (hard)."
    }
  });
});

window.addEventListener("turbolinks:load", () => {
  const form = document.querySelector("#signupform");
  if(!form) { return; }

  setup_check_signup();
  let errors = form.querySelector("p#errors");

  form.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);

    if(data.error) {
      errors.textContent = data.error;
    } else if(data.errors) {
      let first = Object.keys(data.errors)[0];
      errors.textContent = first + " " + data.errors[first];
    } else if(data.redirect) {
      Turbolinks.visit(data.redirect);
    }
  });

  form.addEventListener("ajax:error", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);
    
    if(data.error) {
      errors.textContent = data.error;
    } else {
      errors.textContent = "Server Error (hard)."
    }
  });
});

function setup_check_signup (e) {
  let user_name_input = document.querySelector("#user_name");
  let user_name_availability = document.querySelector("#name_availability");
  let user_password_input = document.querySelector("#user_password");
  let user_password_confirmation = document.querySelector("#user_password_confirmation");
  let name_timeout = null;
  let password_timeout = null;
  var request = new XMLHttpRequest();

  request.onload = function () {
    let data = JSON.parse(this.response);
    user_name_availability.textContent = user_name_input.value+' is '+(data.name?"already taken.":"available!");

    if(data.name) {
      user_name_availability.classList.add("taken");
      user_name_input.classList.add("taken");
    } else {
      user_name_availability.classList.remove("taken");
      user_name_input.classList.remove("taken");
    }
  }

  user_name_input.addEventListener('keyup', function (e) {
    clearTimeout(name_timeout);
    name_timeout = setTimeout(function () {
      request.open('GET', '/userinfo/'+user_name_input.value, true);
      request.send();
    }, 600);
  });

  user_password_confirmation.addEventListener('keyup', function (e) {
    clearTimeout(password_timeout);
    password_timeout = setTimeout(function () {
      if(user_password_input.value !== user_password_confirmation.value) {
        user_password_confirmation.classList.add("differs");
      } else {
        user_password_confirmation.classList.remove("differs");
      }
    }, 600);
  });
}

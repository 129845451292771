window.addEventListener("turbolinks:load", () => {
  const form = document.querySelector("#inviteform");
  if(!form) { return; }
  let errors = form.querySelector("p#errors");

  form.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);

    if((data.status === 404) || (data.status === 500)) {
      errors.textContent = data.error;
    } else if(data.status === 200) {
      location.reload();
    }
  });

  form.addEventListener("ajax:error", (event) => {
    errors.textContent = "Server Error (hard)."
  });
});

window.usermenu_add = function() {
  let addmenu = document.querySelector("#addmenu");
  addmenu.classList.toggle("visible");
}

window.addmenu_form_open = function(type) {
  let post_form = document.querySelector("#postform");
  post_form.classList.add("visible");
  post_form.querySelector("input[name='type']").value = type;
  post_form.querySelector("span.type").textContent = type.charAt(0).toUpperCase()+type.slice(1);

  // clear input
  let fields = post_form.querySelectorAll("input[data-post-input]");
  fields.forEach(function(field) {
    field.value = "";
  });
  fields = post_form.querySelectorAll("textarea[data-post-input]");
  fields.forEach(function(field) {
    field.value = "";
  });

  // clear errors
  post_form.querySelector("p#errors").textContent = "";

  // reset button text
  post_form.querySelector("input[type='submit']").value = "Save Post";

  // enable fields and button
  postform_enable_fields();
  post_form.querySelector("input[type='submit']").disabled = false;

  // hide all form fields
  fields = post_form.querySelectorAll("[data-post-input]");
  fields.forEach(function(field) {
    field.classList.remove("visible");
  });

  // show form fields for type
  fields = post_form.querySelectorAll("[data-post-"+type+"='true']");
  fields.forEach(function(field) {
    field.classList.add("visible");
  });

  // set icon
  let icons = {
    "text":   "TextPost",
    "image":  "ImagePost",
    "video":  "VideoPost",
  };
  let icon = post_form.querySelector("div.icon.type");
  icon.className="icon type "+icons[type];
}

window.postform_add_field = function(name) {
  let post_form = document.querySelector("#postform");
  let fields = post_form.querySelectorAll("[name="+name+"]");
  fields.forEach(function(field) {
    field.classList.add("visible");
  });

  fields = post_form.querySelectorAll("a[data-name="+name+"]");
  fields.forEach(function(field) {
    field.classList.add("visible");
  });

  fields = post_form.querySelectorAll("[data-replaces='"+name+"']");
  fields.forEach(function(field) {
    field.classList.remove("visible");

    // remove the content too
    field.value = "";
  });
}

window.postform_loading = function() {
  let post_form = document.querySelector("#postform");
  let button = post_form.querySelector("input[type='submit']");
  button.disabled = true;
  button.value = "Posting..."

  // disable inputs
  let fields = post_form.querySelectorAll("input[data-post-input],textarea[data-post-input]");
  fields.forEach(function(field) {
    field.disabled = true;
  });
}

window.postform_enable_fields = function() {
  let fields = document.querySelectorAll("#postform input[data-post-input],textarea[data-post-input]");
  fields.forEach(function(field) {
    field.disabled = false;
  });
}

window.addmenu_form_close = function() {
  document.querySelector("#postform").classList.remove("visible");
}

window.addEventListener("turbolinks:load", () => {
  const form = document.querySelector("#postform form");
  if(!form) { return; }
  let button = form.querySelector("input[type='submit']");
  let errors = form.querySelector("p#errors");

  form.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    data = JSON.parse(xhr.responseText);

    if(data.status === 422) {
      if(data.error) {
        if(data.error.endsWith("_mime")) {
          errors.textContent = "MIME type not allowed.";
        } else if(data.error.endsWith("_size")) {
          errors.textContent = "File too large.";
        } else if(data.error.endsWith("_attach")) {
          errors.textContent = "Error attaching file. Please check your file.";
        } else if(data.error.endsWith("_unknown")) {
          errors.textContent = "Could not embed media: Unknown site.";
        } else if(data.error === "post_empty") {
          errors.textContent = "Your post is empty.";
        }
      } else if(data.errors) {
        let first = Object.keys(data.errors)[0];
        errors.textContent = first + " " + data.errors[first];
      }
      postform_enable_fields();
      button.disabled = false;
      button.value = "Save Post";
    } else if(data.status === 403) {
      button.value = "Session lost, please log in again.";
      postform_enable_fields();
    } else if(data.status === 404) {
      button.value = "Invalid post data. Try something else.";
    } else if(data.status === 500) {
      button.value = "Server Error (soft)."
      postform_enable_fields();
    } else if(data.status === 200) {
      button.value = "Success.";
      addmenu_form_close();
      postform_enable_fields();
      button.value = "Save Post";
      button.disabled = false;

      Turbolinks.visit(window.location.href.match(/^https?:\/\/(?:[\w\-]+\.[^\/]+\/)/)[0]);
    }
  });

  form.addEventListener("ajax:send", (event) => {
    postform_loading();
  });

  form.addEventListener("ajax:error", (event) => {
    button.value = "Server Error (hard)."
    postform_enable_fields();
  });
});

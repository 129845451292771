// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
//require("channels")

require("jquery")

require("packs/form_generic")
require("packs/form_signup")
require("packs/form_settings")
require("packs/invite_codes")
require("packs/usermenu")
require("packs/endless_scrolling")
require("packs/flagmodal")
//require("packs/keybinds")

require("featherlight")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import LocalTime from "local-time"
LocalTime.start()

// store browser timezone in tz cookie so we can work with it
document.cookie = "tz_offset="+(new Date()).getTimezoneOffset();
window.open_flagmodal = function(e, id) {
    // check if we're already showing a modal
    if (window.onclick != null) { return; }
    // find modal
    let flagmodal = document.querySelector(".modal.flagmodal-"+id);
    // make modal visible
    flagmodal.classList.add("visible");
    // set position for modal from click position
    flagmodal.style.position = "absolute";
    flagmodal.style.left     = Math.floor(e.pageX)+"px";
    flagmodal.style.top      = Math.floor(e.pageY - flagmodal.offsetHeight)+"px";
    // add onclick to window so we can hide the modal again
    window.onclick = function() {
        flagmodal.classList.remove("visible");
        // remove onclick again after it happened
        window.onclick = null;
    }
    // but don't propagate this event so we don't hide again immediately
    e.stopPropagation();
}

window.report_post = function(id, what) {
    let qresult = window.confirm(
        `Are you sure you want to report this post as ${what}?\nThis cannot be undone.`
    );

    if (qresult) {
        // user really wants to report, make api request
        var request = new XMLHttpRequest();

        request.onload = function(){
            var data;
            try {
                data = JSON.parse(this.response);
            } catch {
                window.alert("Server response not understood, is Souper down?\nPlease try again later.");
                return;
            }

            if(data.status === "OK") {
                window.alert("Report sent; it will be reviewed but you will not get notified of the result.");
            } else if(data.status === "NO") {
                window.alert(data.message);
            } else if(data.error) {
                window.alert(data.error);
            } else {
                window.alert("An unknown error occured.");
            }
        }

        request.open('POST', '/report/create', true);
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr("content"));
        request.send(JSON.stringify({
            reportable: "post",
            type: what,
            id: id
        }));
    }
}